<script setup lang="ts">
defineProps({
  error: {
    type: Object as PropType<Error>,
    required: true,
  },
});

function clearAndReload() {
  clearError();

  reloadNuxtApp({ path: "/" });
}
</script>

<template>
  <NuxtLayout name="default">
    <main
      class="flex min-h-screen w-screen flex-col items-center justify-center overflow-hidden bg-white px-6 py-24 dark:bg-blue-950 sm:py-32 lg:px-8"
    >
      <div class="flex w-full flex-col items-center text-center">
        <h1 class="text-5xl font-bold text-indigo-600 dark:text-white">
          {{ error?.statusCode }}
        </h1>
        <code class="mt-4 text-xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-3xl">
          {{ error.message }}
        </code>
        <button
          type="button"
          tabindex="1"
          class="mt-4 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-500 dark:bg-blue-primary"
          @click="clearAndReload"
        >
          Home
        </button>

        <div
          v-if="error?.stack"
          class="mt-6 flex w-full flex-col items-start rounded-lg bg-gray-200 p-10 pl-3 pt-5 text-base leading-7 text-gray-600 lg:w-3/4"
        >
          <div
            class="max-h-[450px] max-w-full overflow-scroll [&>pre]:flex [&>pre]:flex-col [&>pre]:items-start"
            v-html="error?.stack"
          ></div>
        </div>
      </div>
    </main>
  </NuxtLayout>
</template>
