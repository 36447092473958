export const useHttpErrorHandler = async ({
  errorCode = "",
  statusCode = "",
  response,
  notificationStore,
  refreshCallback,
  endCallback,
}) => {
  if (errorCode) {
    if (errorCode === "401002") {
      return endCallback();
    }

    if (errorCode === "401004") {
      if (response._data?.data?.details?.canBeRefreshed) {
        return refreshCallback();
      }

      return endCallback();
    }

    if (errorCode === "401001") {
      // we skip this error and let user try to pass his 2FA, if it activated
      notificationStore.ADD({
        title: "To Log-In continue Two-Factor Authentication",
      });

      return;
    }

    if (errorCode === "429001") {
      notificationStore.ADD({
        title: "Your account was blocked!",
      });

      return useRouter().push({ name: "home" });
    }

    if (errorCode === "404001") {
      return clearError({ redirect: "/404/not-found" });
    }
  }

  if (statusCode.toString().startsWith("4")) {
    const message =
      response._data?.error ||
      response._data?.data?.message ||
      response._data?.data?.error ||
      "Oops, some error occurs";

    if (statusCode === 403) {
      notificationStore.ADD({
        type: "error",
        title: "Forbidden",
        message,
      });

      return;
    }

    // if (statusCode === 404) {
    //   notificationStore.ADD({
    //     type: "error",
    //     title: "Not Found",
    //     message,
    //   });

    //   return useRouter().push({ name: "home" });
    // }

    notificationStore.ADD({
      type: "error",
      title: "Alert",
      message,
    });
  }

  if (statusCode.toString().startsWith("5")) {
    notificationStore.ADD({
      type: "error",
      title: "Server Alert",
      message: response._data?.data?.error || "Oops, some server error occurs",
    });
    return;
  }
};
