import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => ({
    user: {
      _id: "",
      userName: "",
      fullName: "",
      email: "",
      avatar: "",
      verify: false,
      balanceBNB: 0,
      BSCTokens: 0,
      BSCWalletAddress: "",
      country: "",
      role: 997,
      has2FA: false,
      provider: "EMAIL",
      externalWalletProviders: [],
    },
    session: {
      accessToken: "",
      refreshToken: "",
    },
    utils: {
      countries: [],
    },
  }),

  getters: {
    IS_AUTHORIZED: (state) => {
      return !!state.session.accessToken;
    },
    IS_SIMPLE_DIMPLE: (state) => {
      return state.user.role < 3;
    },
    IS_BIG_BOSS: (state) => {
      return useRuntimeConfig().public.env === "production" ? state.user.role < 2 : state.user.role < 3;
    },
    GET_ACCESS_TOKEN: (state) => {
      return state.session.accessToken;
    },
    GET_REFRESH_TOKEN: (state) => {
      return state.session.refreshToken;
    },
    GET_WALLET_ADDRESS: (state) => {
      return state.user.BSCWalletAddress;
    },
    GET_USER: (state) => {
      return state.user;
    },
  },

  actions: {
    /**
     *
     * @param {User} user
     */
    LOGIN(
      {
        _id,
        userName,
        fullName = "",
        verify,
        avatar,
        accessToken,
        refreshToken,
        balanceBNB,
        country,
        BSCTokens,
        BSCWalletAddress,
        birthDate,
        gender,
        email,
        role = 10,
        has2FA,
        provider,
        externalWalletProviders,
      } = null
    ) {
      if (!email || !userName) {
        navigateTo({ name: "update-user-info", query: { token: accessToken } });
      } else {
        this.user = {
          _id,
          userName,
          fullName,
          verify,
          avatar,
          balanceBNB,
          BSCTokens,
          country,
          BSCWalletAddress,
          email,
          birthDate,
          role,
          gender,
          has2FA,
          provider,
          externalWalletProviders,
        };

        this.session = { accessToken, refreshToken };

        navigateTo({ name: "home" });
      }
    },

    /**
     * @param {Object} options
     * @param {string} options.accessToken
     * @param {string} options.refreshToken
     */
    REFRESH_SESSION({ accessToken, refreshToken }) {
      this.session = { accessToken, refreshToken };
    },

    LOGOUT() {
      this.$reset();

      navigateTo({ name: "sign-in" });
    },

    UPDATE_USER(payload = {}) {
      this.user = { ...this.user, ...payload };
    },

    async FETCH_COUNTRIES() {
      try {
        const { data: result } = await useNuxtApp().$api.get("user/countries", {
          transform(response) {
            return response.result;
          },
        });

        this.utils.countries = result.value;
      } catch (error) {
        console.error(error);
      }
    },
  },

  persist: [
    {
      paths: ["user"],
      storage: persistedState.localStorage,
    },
    {
      paths: ["session"],
      storage: persistedState.cookiesWithOptions({}),
      key: "session",
      // afterRestore: (state) => {
      //   if (!state.accessToken && !state.refreshToken) {
      //     state.store.$reset();
      //   }
      // },
    },
  ],
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}

/**
 *
 * @typedef {Object} User
 * @property {string} userName
 * @property {string} avatar
 * @property {boolean} verify
 * @property {string} accessToken
 * @property {string} refreshToken
 * @property {number} balanceBNB
 * @property {number} BSCTokens
 * @property {string} BSCWalletAddress
 * @property {string} country
 * @property {string} gender
 * @property {string} birthDate
 * @property {object} linkedArtist
 * @property {string} linkedArtist._id
 *
 *
 *
 * */
