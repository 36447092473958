export const objectToFormData = (
  /** @type {{ [x: string]: any; hasOwnProperty: (arg0: string) => any; }} */ objToConvert,
  formData = new FormData(),
  namespace = ""
) => {
  for (const property in objToConvert) {
    // eslint-disable-next-line no-prototype-builtins
    if (objToConvert.hasOwnProperty(property)) {
      const key = namespace ? `${namespace}[${property}]` : property;
      const value = objToConvert[property];

      if (typeof value === "object" && !(value instanceof File)) {
        objectToFormData(value, formData, key);
      } else {
        formData.append(key, value);
      }
    }
  }

  return formData;
};
