export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1, viewport-fit=cover"},{"charset":"utf-8"},{"name":"description","content":"Ceek Marketplace"},{"property":"og:title","content":"CEEK NFT | Discover, Collect, Sell"},{"property":"og:description","content":"Ceek Marketplace"}],"link":[{"rel":"preconnect","href":"https://fonts.googleapis.com"},{"rel":"preload","as":"font","href":"https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600;700&display=swap"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"en"},"charset":"utf-8","viewport":"width=device-width, initial-scale=1, viewport-fit=cover"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'