export const useMarketplaceStore = defineStore("marketplace", {
  state: () => ({
    exchangeRate: {
      tokenPrice: 0,
      bnbPrice: 0,
      fetchedAt: null,
    },
    nft: {
      id: "",
      minBid: 0,
      currentMinBid: 0,
    },
  }),

  getters: {
    GET_EXCHANGE_RATE: (state) => {
      return state.exchangeRate;
    },
  },

  actions: {
    /**
     * @param {Object} options
     */
    UPDATE_NFT_TOKEN(options = {}) {
      this.nft = { ...this.nft, ...options };
    },

    async UPDATE_EXCHANGE_RATE() {
      const data = await $fetch("/api/exchange-rate");

      if (data.tokenPrice && data.bnbPrice)
        this.exchangeRate = { tokenPrice: data.tokenPrice, bnbPrice: data.bnbPrice };
    },
  },
});
