import * as Sentry from "@sentry/vue";

async function lazyLoadSentryIntegrations() {
  // don't load on server
  if (!process.client) return;

  const { Replay } = await import("@sentry/vue");
  Sentry.addIntegration(
    new Replay({
      maskAllText: false,
      blockAllMedia: false,
    })
  );
}

function getSentryIntegrations() {
  // don't load on server
  if (!process.client) return [];

  const router = useRouter();
  const browserTracing = new Sentry.BrowserTracing({
    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  });

  return [browserTracing];
}

export default defineNuxtPlugin({
  name: "sentry",
  parallel: true,
  async setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp;

    const {
      public: { sentry },
    } = useRuntimeConfig();

    if (!sentry?.dsn) {
      return;
    }

    Sentry.init({
      app: vueApp,
      dsn: sentry.dsn,
      environment: sentry.environment,
      integrations: getSentryIntegrations(),

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0, // Change in prod

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["https://nft.ceek.com", "https://test.nft.ceek.com"],

      replaysSessionSampleRate: 0.1, // Change in prod
      replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
    });

    // Lazy-load the replay integration to reduce bundle size
    lazyLoadSentryIntegrations();
  },
});
