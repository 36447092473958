export const useNotificationStore = defineStore("notification", {
  state: () => ({
    buffer: [],
    updateTimeout: null,
  }),

  getters: {
    GET_ALL: (state) => {
      return state.buffer;
    },
  },

  actions: {
    ADD({ type = "info", title = "", message = "" }) {
      this.buffer.push({ type, title, message, id: new Date().getTime() });
    },

    REMOVE(index) {
      this.buffer.splice(index, 1);
    },
  },
});
